<template>
    <v-app>
        <v-container grid-list-md text-xs-center fluid>
            <h1>Carregadores</h1>
            <v-divider class="mb-4" />
            <v-list v-if="chargesPending.length > 0" shaped class="pb-12">
                <v-subheader>Carregador(es) Pendente(s)</v-subheader>
                <v-list-item-group v-model="chargesPending" color="primary">
                    <v-list-item class="elevation-2" v-for="(charge, i) in chargesPending" :key="i">
                        <v-list-item-icon>
                            <v-btn fab small color="success" @click="openChargePending(charge)">
                                <v-icon>cloud_upload</v-icon>
                            </v-btn>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-layout wrap>
                                <v-flex xs12 sm6>
                                    <v-list-item-title v-text="charge.UUID" class="mx-2"></v-list-item-title>
                                </v-flex>
                                <v-flex xs12 sm6 class="text-sm-right">
                                    <v-btn v-if="charge && charge.vpnIp" small color="blue-grey" class="white--text" :href="`http://${charge.vpnIp}`" target="_blank">
                                        <v-icon left>vpn_lock</v-icon>
                                        VPN: {{ charge.vpnIp }}
                                    </v-btn>
                                </v-flex>
                            </v-layout>
                        </v-list-item-content>
                    </v-list-item>
                </v-list-item-group>
            </v-list>

            <template>
                <v-alert outlined class="mt-12" color="red" prominent border="top" icon="info" v-if="charges.length == 0"> Nenhum carregador encontrado. </v-alert>
            </template>

            <v-layout wrap v-if="charges.length > 0">
                <v-flex xs12 md6>
                    <v-switch v-model="enable" label="Somente carregadores habilitados" @change="updateCharger(false)" />
                </v-flex>

                <v-flex xs12 md6>
                    <v-text-field label="Buscar" v-model="filtro" clearable />
                </v-flex>
            </v-layout>

            <div v-for="charge of listWithFilter" :key="charge.UUID">
                <ChargerView :charge="charge" />
            </div>
        </v-container>
        <ConfirmPending :show="confirmPending.show" :item="confirmPending.item" @onClose="closeConfirmPending()"></ConfirmPending>
    </v-app>
</template>

<script>
import ChargerView from "./ChargerView";
import ConfirmPending from "./ConfirmPending";

export default {
    name: "Charger",

    components: { ChargerView, ConfirmPending },

    data() {
        return {
            status: "init",
            timer: null,
            filtro: "",
            enable: true,
            charges: [],
            chargesPending: [],
            confirmPending: {
                show: false,
                item: { model: null },
            },
        };
    },

    computed: {
        listWithFilter() {
            if (this.filtro) {
                let exp = new RegExp(this.filtro.trim(), "i");
                return this.charges.filter((charge) => exp.test(charge.UUID) || exp.test(charge.name) || (charge.point && exp.test(charge.point.name)));
            } else {
                return this.charges;
            }
        },
    },

    created() {
        this.update();
        this.timer = setInterval(this.update, 5000);
    },

    methods: {
        openChargePending(item) {
            this.confirmPending.item = Object.assign({ incharge: true }, item);
            this.confirmPending.show = true;
        },
        closeConfirmPending() {
            this.confirmPending.item = [];
            this.confirmPending.show = false;
        },
        updatePending() {
            this.$http
                .get("api/v2/pending-charger", { continuous: true })
                .then((result) => {
                    this.chargesPending = result;
                })
                .catch(() => {
                    this.chargesPending = [];
                });
        },
        updateCharger(continuous = true) {
            this.$http
                .get(`api/v2/charger/admin?enable=${this.enable}`, { continuous })
                .then((result) => {
                    this.charges = result;
                })
                .catch(() => {
                    this.$eventHub.$emit("msgError", "Erro ao buscar informações.");
                });
        },
        update() {
            switch (this.status) {
                case "init":
                    this.updateCharger();
                    this.updatePending();
                    this.status = "online";
                    break;
                case "online":
                    this.updateCharger();
                    this.status = "pending";
                    break;
                case "pending":
                    this.updatePending();
                    this.status = "online";
                    break;
            }
        },
    },

    beforeDestroy() {
        clearInterval(this.timer);
    },
};
</script>
