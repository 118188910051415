<template>
    <v-layout row justify-center>
        <v-dialog v-model="show" fullscreen hide-overlay transition="dialog-bottom-transition">
            <v-card>
                <v-toolbar dark color="primary">
                    <v-btn icon dark @click="close">
                        <v-icon>close</v-icon>
                    </v-btn>
                    <v-toolbar-title>Confirma autorização do modelo {{ item.model }}?</v-toolbar-title>
                </v-toolbar>

                <v-card color="primary" class="white--text">
                    <v-card-title primary-title>
                        <div>
                            <span>Autorizando o carregador {{ item.UUID }} os dados já começarão a ser salvos automaticamente.</span>
                        </div>
                    </v-card-title>
                </v-card>
                <v-form ref="form" v-model="valid" lazy-validation @submit.prevent="sendAutorizateCharger">
                    <v-container grid-list-xl>
                        <v-layout wrap>
                            <v-flex xs12 sm4>
                                <v-text-field label="Gateway" v-model="item.board" disabled />
                            </v-flex>
                            <v-flex xs12 sm4>
                                <v-text-field label="Nome (link)" :rules="[rules.required]" v-model="item.name" required />
                            </v-flex>
                            <v-flex xs12 sm4>
                                <v-select v-model="item.idPoint" item-value="id" item-text="name" :items="clients" label="Cliente" :disabled="clients.length === 0"
                                    :loading="clients.length === 0" />
                            </v-flex>
                            <v-flex xs12 sm4>
                                <v-select v-model="item.connection" :rules="[rules.required]" :items="connections" label="Conexão" />
                            </v-flex>
                            <v-flex xs12 sm4>
                                <v-select v-model="item.policy" :items="policies" :rules="[rules.required]" label="Política de acesso" />
                            </v-flex>
                            <v-flex xs12 sm4>
                                <v-switch v-model="item.incharge" label="Carregador INCHARGE?" color="success" />
                            </v-flex>
                            <v-flex xs12>
                                <v-autocomplete v-model="item.protocol" :items="protocols" item-value="id" item-text="name" label="Protocolo" deletable-chips small-chips />
                            </v-flex>
                            <v-flex xs12 sm12>
                                <v-btn :disabled="!valid" color="success" @click="sendAutorizateCharger">Autorizar</v-btn>
                            </v-flex>
                        </v-layout>
                    </v-container>
                </v-form>
            </v-card>
        </v-dialog>
    </v-layout>
</template>

<script>
import protocols from "@/assets/json/protocols.json";
import connections from "@/assets/json/connections.json";
import policies from "@/assets/json/policies.json";
import rules from "@/helpers/rules";

export default {
    props: {
        show: {
            type: Boolean,
            required: true,
        },
        item: {
            type: Object,
            required: true,
        },
    },

    data() {
        return {
            rules,
            valid: true,
            clients: [],
            connections,
            protocols,
            policies,
        };
    },

    methods: {
        sendAutorizateCharger() {
            if (this.$refs.form.validate()) {
                this.$http
                    .post("api/v2/pending-charger", this.item)
                    .then(() => {
                        this.$eventHub.$emit("msgSuccess", "Carregador autorizado com sucesso " + this.item.UUID + ".");
                        this.item = [];
                        this.close();
                    })
                    .catch(() => {
                        this.$eventHub.$emit("msgError", "Ocorreu erro ao autorizar o carregador " + this.item.UUID + ". Contate o administrador do sistema.");
                    });
            }
        },
        close() {
            this.$emit("onClose");
        },
        init() {
            this.$http
                .get("api/v2/point")
                .then((result) => {
                    this.clients = result;
                })
                .catch(() => {
                    this.clients = [];
                });
        },
    },

    created() {
        setTimeout(this.init, 5000);
    },
};
</script>